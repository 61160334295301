export default {
    'title.home': 'Finkernagel – Wire mill and Forming tools made in Germany',
    'title.company': 'Company',
    'title.wire': 'Wire',
    'title.forming-tools': 'Forming tools',
    'title.jobs': 'Career',
    'title.contact': 'Contact',
    'title.staff': 'Contact Persons',
    'title.news': 'Latest news',
    'title.stats': 'The figures',
    'title.environment': 'Sustainability',
    'title.services': 'Our services',
    'title.history': 'History',
    'title.downloads': 'Downloads',
    'title.privacy': 'Privacy Policy',
    'title.imprint': 'Legal notice',
    'title.dsgvo': 'Privacy Policy',
    'title.delivery-program.wire': 'Delivery programme wire',
    'title.delivery-program.forming-tools': 'Delivery programme forming tools',

    'global.explore': 'Learn more',
    'global.back': 'Back',
    'global.plan-route': 'Link to Google Maps',
    'global.explore-jobs': 'Career at Finkernagel',
    'global.explore-apprenticeship': 'Training at Finkernagel',

    'home.hero.title': 'Wire is not just wire',
    'home.hero.copy': 'Finkernagel – Our name stands for high-performance cold heading wires and the highest quality forming tools. Their benefits are enjoyed by our customers from automotive engineering, construction, electrical and machine engineering industries worldwide.',
    'home.hero.explore': 'More about the company',

    'stat.employees': 'Employees',
    'stat.tons-per-month': 'Wire per month',
    'stat.dimension-range': 'Dimension range',
    'stat.production-space': 'Production area',
    'stat.tools-per-month': 'Tools per month',
    'stat.solar-modules': 'Photovoltaic modules',
    'stat.solar-power': 'Output from photovoltaic system',
    'stat.co2-savings': 'CO₂ saved annually',
    'stat.co2-savings-percent': "CO₂ savings",
    'stat.resources': "Cycle",
    'stat.digitalisation': "Sheets of paper per year",
    'stat.logistics': 'Engagement',
    'stat.mobility':'Charging stations',

    'history.1738': 'The first ever mention of a wire drawing mill on our site – “Pastors roll”',
    'history.1926': 'Take-over by Fritz Finkernagel',
    'history.1946': 'Son Friedrich-Wilhelm Finkernagel (right) joins the company',
    'history.1963': 'Establishment of the forming tools department',
    'history.1984': 'With Fritz Uwe Finkernagel (right) joining, the company is in its third generation',
    'history.1991': 'Range of sizes increased with commissioning of horizontal draw mill',
    'history.1993': 'Commissioning one of Europe’s first HICON/H₂ bell-type annealing plants for cold heading wire',
    'history.1997': 'Commissioning of fully-automatic pickling and coating plant',
    'history.2005': 'Expansion of operations with brand new 4.000 m² production building',
    'history.2015': 'With Timo Finkernagel joining, the company is now in its fourth generation',
    'history.2017': 'Further expansion with new 3.000 m² production building',

    'page.home.description': 'Finkernagel supplies cold heading wire and forming tools of the highest standard to the automotive, building, electrical and mechanical engineering industries.',
    'page.home.keywords': 'Cold heading wire, Forming tools',
    'page.home.archive': 'To our archive',
    'page.home.sustainability.title': 'Sustainability',
    'page.home.sustainability.paragraph': 'For generations, it has been a matter of course for us to work as resource-efficiently as possible. Reducing the CO2 footprint is only possible if the entire supply chain is considered and optimized accordingly.',
    'page.home.sustainability.subheading': 'We divide our activities into six areas of sustainability',
    'page.home.sustainability.material.title': 'Raw material',
    'page.home.sustainability.material.paragraph': 'As partners with numerous renowned steel suppliers, we provide recycled and sustainably manufactured wire, such as XCarb® by ArcelorMittal, allowing for up to 80 percent CO₂ savings.',
    'page.home.sustainability.energy.title': 'Energy',
    'page.home.sustainability.energy.paragraph': 'With 1,710 solar panels on our roofs, we avoid 300 tons of CO₂ emissions per year and cover 20% of our electricity consumption.',
    'page.home.sustainability.resources.title': 'Resource efficiency',
    'page.home.sustainability.resources.paragraph': 'Expansion of the use of hydrogen for our heat treatment and harnessing the waste heat from our annealing furnaces.',
    'page.home.sustainability.digitalisation.title': 'Digitization',
    'page.home.sustainability.digitalisation.paragraph': 'Through the ongoing digitization of company processes, we save paper year after year, for example by introducing digital internal and external audit documents.',
    'page.home.sustainability.logistics.title': 'Logistics',
    'page.home.sustainability.logistics.paragraph': 'Optimization of our logistics, such as increasing truck utilization.',
    'page.home.sustainability.mobility.title': 'Mobility',
    'page.home.sustainability.mobility.paragraph': 'Installation of electric vehicle charging stations and gradual transitionof our fleet to fully electric.',
    'page.home.sustainability.download': 'Sustainability at Finkernagel',
    'page.home.sustainability.download.link': '/static/Sustainability.pdf',

    'page.company.description': 'Finkernagel supplies cold heading wire and forming tools of the highest standard to the automotive, building, electrical and mechanical engineering industries.',
    'page.company.keywords': 'Cold heading wire, EN 10263, wire, Forming tools',

    'page.wire.description': 'Take a look at our innovative wire production and the variety of delivery forms to choose from.',
    'page.wire.keywords': 'Cold heading wire, EN 10263, wire',

    'page.forming-tools.description': 'We supply forming tools in carbide and steel in a wide range of designs and sizes.',
    'page.forming-tools.keywords': 'Forming tools',

    'page.contact.headline': 'By your side with advice and wire',
    'page.contact.description': 'Get in touch with Finkernagel.',
    'page.contact.business-card': 'Digital Business Card',

    'page.downloads.description': 'Certificates, terms and conditions and more.',

    'page.privacy.description': 'General information about data processing by the company Finkernagel.',

    'page.jobs.headline': 'Career at Finkernagel',
    'page.jobs.description': 'Looking for a new challenge in an innovative working environment? Then we need to meet!',
    'page.jobs.survey.description': 'of our employees recommend Finkernagel as an employer.',
    'page.jobs.survey.source': 'according to the current employee survey',

    'page.jobs.our-values.title': 'Our values',
    'page.jobs.our-values.spirit.title': 'Drive',
    'page.jobs.our-values.spirit.paragraph':
      'When it comes to meeting customer require­ments, we show drive and full commit­ment. This has often enabled us to turn the impossible into reality.',
    'page.jobs.our-values.precision.title': 'Precision',
    'page.jobs.our-values.precision.paragraph':
      'We stand for the highest quality and precision in all parts that leave our production. For us, every μm counts. We not only detect defects, but also mark them directly so that precise further pro­cessing is possible.',
    'page.jobs.our-values.keep-word.title': 'Keeping our word',
    'page.jobs.our-values.keep-word.paragraph':
      'When we give our word, it is a promise. We act reliably, transpar­ently and responsibly towards customers, partners and, of course, within Finkernagel.',
    'page.jobs.our-values.foresight.title': 'Foresight',
    'page.jobs.our-values.foresight.paragraph':
      'We are proud of our almost one hundred years of company history. Long-term success and sustain­ability are particularly important to us as a family business. That is why we are now in the fourth generation of our family to work with foresight on innovative solutions for the future.',
  
    'page.news.description': 'Latest news from Finkernagel',
}
